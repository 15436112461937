import React, { useEffect, useState } from 'react';
import request from '../../../service/request';
import cookie from '../../../components/react-cookie';
import { CITYCODE_GET } from '../../../service/api';

const cityCode = cookie.load('cityCode');

export function getVerificationCode(isLogin){
    return new Promise((resolve,reject)=>{
        request.GET({
            url: `/web/qyld/verification/code/get?logIn=${isLogin?1:2}`,
        }).then((res) => {
            if(res.code == 0){
                resolve(res)
            }else{
                reject(res.errorMsg)
            }
        }).catch((error) => {
            console.log(error, 'error');
            reject(error)
        });
    })
}
/*
* 获取小区
* houseType 房源类型 1 租赁 2 二手
* name 小区名称
*/ 
export function getEstateList(arg){
    const {houseType,name,cityId,cityCode} = arg;
    return new Promise((resolve,reject)=>{
        request.POST({
            url: `/web/qyld/estate/baseInfo/get`,
            params: {
                "channel": 3,
                "cityCode": cityCode,
                "houseType": houseType,
                "name": name,
                "cityId":cityId
            }
        }).then((res) => {
            resolve(res)
        }).catch((error) => {
            console.log(error, 'error');
            reject(error)
        });
    })
}

/*
* 获取城市名称
* cityName
*/ 
export function getCityList(arg){
    let list = [];
    return new Promise((resolve,reject)=>{
        request.GET({
            url: `/fetch/xcx/common/web/siteList`,
        }).then((res) => {
            const siteList = res.data.cityList
            try{
                if(res.code == 0){
                    let nowCityInfo = {
                        abbreviation: "sz",
                        cityCode: "320500",
                        cityId: "1B92E5CA-E29B-4454-8726-55C73C696EB2",
                        cityName: "苏州市",
                        pinyin: "suzhou",
                        shortName: "苏州"
                    };
                    let result = [];
                    for(let key in siteList){
                        if(key != 'K') {
                            result.push({
                                initials:key,
                                regionCityVOList:siteList[key]
                            })
                        };
                    }
                    result.some((items,index) => {
                        return items.regionCityVOList.some( (item)=>{
                            if(item.cityCode == cityCode) {
                                nowCityInfo = {...item,abbreviation:item.shortPin};
                                return true;
                            }
                            return false;
                        })
                    })
                    getCityId(nowCityInfo.cityCode).then((resB)=>{
                        resolve({data:result,nowCityInfo:{...nowCityInfo,cityId:resB}})
                    })
                    
                }else{
                    reject(res.errorMsg)
                }
            }catch(error){
                reject(error)
            }
        }).catch((error) => {
            console.log(error, 'error');
            reject(error)
        });
    })
}

// 获取当前开放城市
export function getNowCityList(arg){
    let list = [];
    return new Promise((resolve,reject)=>{
        request.GET({
            url: CITYCODE_GET,
        }).then((res) => {
            const siteList = res.data.cityList
            try{
                if(res.code == 0){
                    let nowCityInfo = {
                        abbreviation: "sz",
                        cityCode: "320500",
                        cityId: "1B92E5CA-E29B-4454-8726-55C73C696EB2",
                        cityName: "苏州市",
                        pinyin: "suzhou",
                        shortName: "苏州"
                    };
                    let result = [];
                    for(let key in siteList){
                        // if(key != 'K') {
                            result.push({
                                initials:key,
                                regionCityVOList:siteList[key]
                            })
                        // };
                    }
                    result.some((items,index) => {
                        return items.regionCityVOList.some( (item)=>{
                            if(item.cityCode == cityCode) {
                                nowCityInfo = {...item,abbreviation:item.shortPin};
                                return true;
                            }
                            return false;
                        })
                    })
                    getCityId(cityCode=='320583'?'320500':cityCode).then((resB)=>{
                        resolve({data:result,nowCityInfo:{...nowCityInfo,cityId:resB}})
                    })
                    
                }else{
                    reject(res.errorMsg)
                }
            }catch(error){
                reject(error)
            }
        }).catch((error) => {
            console.log(error, 'error');
            reject(error)
        });
    })
}
// citycode 获取 cityid
export function getCityId(code){
    return new Promise((resolve,reject)=>{
        request.GET({
            url: `/web/qyld/city/by/code/get?cityCode=${code}`,
        }).then((res) => {
            if(res.code == 0){
                resolve(res.data?res.data.cityId:'')
            }else{
                reject(res.errorMsg)
            }
        }).catch((error) => {
            console.log(error, 'error');
            reject(error)
        });
    })
}


/*
* 获取小区楼栋列表
*  文档 http://192.168.106.218:20025/swagger/doc.html#/%E4%BA%AC%E6%97%A5%E6%89%BE%E6%88%BF/%E5%9C%A8%E7%BA%BF%E5%A7%94%E6%89%98/getBlockListUsingPOST
*/ 
export function getEstateBlockList(arg){
    const {abbreviation,cityCode,cityId,estateNewBaseInfoId,name,page} = arg;
    return new Promise((resolve,reject)=>{
        request.POST({
            url: `/web/qyld/estate/block/list/get`,
            params:{abbreviation,cityCode,cityId,estateNewBaseInfoId,name,page,size:10}
        }).then((res) => {
            try{
                if(res.code == 0){
                    resolve({...res})
                }else{
                    reject(res.errorMsg)
                }
                
            }catch(error){
                reject(error)
            }
        }).catch((error) => {
            console.log(error, 'error');
            reject(error)
        });
    })
}

/*
* 获取单元名称与门牌号
*  文档 http://192.168.106.218:20025/swagger/doc.html#/%E4%BA%AC%E6%97%A5%E6%89%BE%E6%88%BF/%E5%9C%A8%E7%BA%BF%E5%A7%94%E6%89%98/getBlockListUsingPOST
*/ 
export function getEstateBlockCellList(arg){
    const {abbreviation,cityCode,cityId,estateNewBlockId,name,page} = arg;
    return new Promise((resolve,reject)=>{
        request.POST({
            url: `/web/qyld/estate/block/cell/listBlockCellHouse`,
            params:{abbreviation,cityCode,cityId,estateNewBlockId,name,page,size:10}
        }).then((res) => {
            try{
                resolve({...res})
            }catch(error){
                reject(error)
            }
        }).catch((error) => {
            console.log(error, 'error');
            reject(error)
        });
    })
}

// 出售房源保存
export function saveSaleHouseInfo(arg){

    return new Promise((resolve,reject)=>{
        request.POST({
            url: `/web/qyld/house/trade/no/estate/base/save`,
            params:{...arg,channel:3}
        }).then((res) => {
            try{
                resolve({...res})
            }catch(error){
                reject(error)
            }
        }).catch((error) => {
            console.log(error, 'error');
            reject(error)
        });
    })
}

//出租房源保存
export function saveRentHouseInfo(arg){

    return new Promise((resolve,reject)=>{
        request.POST({
            url: `/web/qyld/house/rent/no/estate/base/save`,
            params:{...arg,channel:3,price:arg.salePrice}
        }).then((res) => {
            try{
                resolve({...res})
            }catch(error){
                reject(error)
            }
        }).catch((error) => {
            console.log(error, 'error');
            reject(error)
        });
    })
}