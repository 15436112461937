import axios from 'axios';
import cookie from '../components/react-cookie';


if (typeof window !== 'undefined') {
    axios.defaults.headers = {
        'Content-Type': 'application/json;charset=utf-8'
    };

    const Authorization = cookie.load('jrzf_token');
    if (Authorization) axios.defaults.headers = {
        ...axios.defaults.headers,
        'Authorization': `Bearer ${Authorization}`
    }
    const cityCode = cookie.load('cityCode');
    if (cityCode) axios.defaults.headers = {
        ...axios.defaults.headers,
        'cityCode': cityCode
    }
}

axios.defaults.baseURL = process.env.REACT_APP_BASE_URL

// 添加请求拦截器
axios.interceptors.request.use((config) => {
    if (/^\/api\/oauth\/auth/.test(config.url) == true) {
        config.baseURL = '';
    }
    else if (/^\/fetch/.test(config.url) == true) {
        if(/^\/fetch\/jrzf/.test(config.url)==true){
            config.baseURL = process.env.REACT_APP_BFF_URL.replace('9001','9002');
        }else{
            config.baseURL = process.env.REACT_APP_BFF_URL;
        }
    }
    else {
        config.baseURL = process.env.REACT_APP_BASE_URL
    }
    config.url=config.url.replace('%VERSION%',process.env.REACT_APP_BFF_VERSION)
    return config;
}, (error) => {
    return Promise.reject(error);
});

// 添加响应拦截器，拦截登录过期或者没有权限
axios.interceptors.response.use((response) => {
    return response;
}, (error) => {
    return Promise.reject(error);
});

export default class request {
    static GET (options) {
        return new Promise((resolve, reject) => {
            axios.get(options.url, options.headers ? { headers: options.headers } : {}).then(function (response) {
                resolve(response.data);
            }).catch(function (error) {
                reject(error);
            });
        });
    };

    static POST (options) {
        return new Promise((resolve, reject) => {
            axios.post(options.url, options.params, options.headers ? { headers: options.headers } : {}).then(function (response) {
                resolve(response.data);
            }).catch(function (error) {
                reject(error);
            });
        });
    };

    static PUT (options) {
        return new Promise((resolve, reject) => {
            axios.put(options.url, options.params, options.headers ? { headers: options.headers } : {}).then(function (response) {
                resolve(response.data);
            }).catch(function (error) {
                reject(error);
            });

        });
    };
}
