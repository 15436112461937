import useStyles from 'isomorphic-style-loader/useStyles';
import React, { useEffect, useState } from 'react';
import s from './index.less';

export default function saleHouseForm(props) {
    const {children,header,footer, title,width,height,visible = false,onOk,onCancel,panelClass} = props;
  
    useStyles(s);
    return (
        <>
        {visible?<div className={`modal_panel ${panelClass || ''}`} >
            <div className='bgc_panel' onClick={()=>onCancel()}></div>
            <div className='modal_content' style={{width:width+'px',height:height+'px'}}>
                {
                    header ?header: <>
                        <div className='header'>
                            <div className='title'>{title}</div>
                            <img src="https://static.jingrizf.com/file/957d558a-2bf9-4e4b-b2df-7fdc0d859678.png" className='close_icon' onClick={()=>onCancel()}></img>
                        </div>
                    </>
                }
                {children}
                {
                    footer ?footer: <>
                        <div className='footer'>
                            <div className='btn cancel_btn' onClick={()=>onCancel()}>取消</div>
                            <div className='btn conform_btn' onClick={()=>onOk()}>确认</div>
                        </div>
                    </>
                }
            </div>
        </div>:<></>}
        </>
    )
}