const API = {
    TRADEHOUSE_SEARCH: `/web/tradeHouse/search`,
    TRADEHOUSE_SEARCH_NEW: `/web/search/trade/house`,
    TRADEHOUSE_GET_DETAIL: `/web/tradeHouse/getDetail/`,
    // TRADEHOUSE_GET_MAINMAINTAIN: `/web/tradeHouse/getMainMaintain/`,
    TRADEHOUSE_GET_MAINMAINTAIN: `/web/tradeHouse/getRecommendAgent/`,
    TRADEHOUSE_POST_SEARCH: `/web/tradeHouse/home/search`, // 首页二手房推荐展示
    TRADEHOUSE_GET_QUALIFY: `/web/tradeHouse/getTradeHouseQualify/`, // 二手房查询资质

    RENTHOUSE_SEARCH: `/web/rentHouse/search`,
    RENTHOUSE_SEARCH_NEW: `/web/search/rent/house`,
    RENTHOUSE_GET_DETAIL: `/web/rentHouse/getDetail/`,
    // RENTHOUSE_GET_MAINMAINTAIN: `/web/rentHouse/getMainMaintain/`,
    RENTHOUSE_GET_MAINMAINTAIN: `/web/rentHouse/getRecommendAgent/`,

    NEWHOUSE_SEARCH: `/web/newHouse/search`,
    NEWHOUSE_SEARCH_NEW: `/web/search/new/house`,
    NEWHOUSE_GET_DETAIL: `/web/newHouse/getDetail/`,
    NEWHOUSE_GET_MODELINFO: `/web/newHouse/getModelInfo/`,
    NEWHOUSE_GET_NEWHOUSEIMG: `/web/newHouse/getNewHouseImg/`,
    NEWHOUSE_POST_DYNAMIC: `/web/newHouse/getDynamic/`,

    SEARCH_ADD_HOT_WORD: `/web/search/add/newHouse/word`,

    SHARE_POST: `xcx/share/qr`,//平台小程序码
    ONLINE_STORE_POST: `/sxcx/share/qr`, // 经纪人网店小程序码

    COUNTRY_GET: `/web/country/area/list`,
    TRADE_GET: `/web/trade/`,
    AREA_GET: `/web/country/area/getRegionTree`,

    CUSTOMER_FOCUS_POST: `/web/customer/houseFocus/focus`,                // 关注房源
    CUSTOMER_CANCELFOCUS_POST: `/web/customer/houseFocus/cancelFocus`,    // 取消关注房源
    CUSTOMER_TRADE_FOCUS_POST: `/web/customer/houseFocus/tradeHouse`,     // 二手房
    CUSTOMER_RENT_FOCUS_POST: `/web/customer/houseFocus/rentHouse`,       // 租赁房
    CUSTOMER_NEWHOUSE_FOCUS_POST: `/web/customer/houseFocus/newHouse`,    // 新房

    SENDCODE_POST: `/oauth/phone/sms/web/send/code`,    // 获取验证码
    // LOGIN_PHONE_SMS_POST: LOGIN_API_ADDRESS + '/auth/phone/sms',      // 手机验证码登录
    // LOGIN_POST: LOGIN_API_ADDRESS + '/auth/phone/password/login',     // 账号密码登录
    // RESET_PWD_POST: LOGIN_API_ADDRESS + '/auth/web/reset/password',   // 重新设置密码
    // LOGOUT_POST: LOGIN_API_ADDRESS + '/auth/logout'                   // 登出
    SLIDERCAPTCHA: `/web/sliderCaptcha/isVerify`, // 滑块登录验证
    LOGIN_CHECK: `/oauth/auth/full/info`, // 用户验证

    LOGIN_PHONE_SMS_POST: '/api/oauth/auth/phone/sms',      // 手机验证码登录
    LOGIN_POST: '/api/oauth/auth/phone/password/login',     // 账号密码登录
    RESET_PWD_POST: '/api/oauth/auth/web/reset/password',   // 重新设置密码
    LOGOUT_POST: '/api/oauth/auth/logout',                   // 登出
    TOKEN_EXP: `/oauth/token/exp`,

    POIFATHERTYPE_GET: '/web/poi/getPoiFatherType',  // 小区周边信息父级tab
    ROUNDDETAIL_POST: '/web/poi/getEstateRoundDetailV2',    // 小区周边信息

    // CITYCODE_GET: '/fetch/xcx/common/siteList' // 获取城市列表
    CITYCODE_GET: '/fetch/xcx/web/home/siteList', // 获取城市列表

    CUSETOMER_PERMISSION :"/web/customer/getCustomPermission " //获取用户权限
};

module.exports = API;
