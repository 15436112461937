import useStyles from 'isomorphic-style-loader/useStyles';
import React, { useEffect, useState } from 'react';
import s from './index.less';
import Modal from '../../../../components/modal';
import { getCityId } from "../methods";


export default function chooseNowCityModal(props) {
    const {visible,onOk,onCancel,cityList} = props;
    useStyles(s);
    // 选择城市
    const chooseCity=(e)=>{
        let cityInfo = JSON.parse(e.target.getAttribute('customerdata'));
        if (cityInfo) {
            getCityId(cityInfo.cityCode).then((res)=>{
                onOk('city',{...cityInfo,abbreviation:cityInfo.shortPin,cityId:res})
            })
        }
    }
    return (
        <Modal
            title="选择城市"
            panelClass="choose_city_modal"
            width="1080"
            height="387"
            footer={[]}
            visible={visible}
            // onOk={onOk}
            onCancel={onCancel}
        >
            <div className='outer_hidden_scroll'>
                <div className='hidden_scroll'>
                    <div className="city_list_panel" onClick={(e)=>chooseCity(e)}>
                        {cityList.map((items,index) => {
                            return (
                                <div className={`letter_item ${index==0?'active_letter_item':''}`} key={items.initials}>
                                    <div className="letter">{items.initials}</div>
                                    <div className='region_city_panel'>
                                        {
                                            items.regionCityVOList.map((item)=>{
                                                return (
                                                    <span className='click_city' customerdata={JSON.stringify(item)} key={item.cityCode}>{item.shortName}</span>
                                                )
                                            })
                                        }
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                </div>
            </div>
        </Modal>
    );
}
