/*eslint-disable*/
Object.defineProperty(exports, "__esModule", {
    value: true
});
const typeofCon = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) { return typeof obj; } : function (obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; };

const cookieCon = require('cookie');

exports.load = load;
exports.loadAll = loadAll;
exports.select = select;
exports.save = save;
exports.remove = remove;
exports.setRawCookie = setRawCookie;
exports.plugToRequest = plugToRequest;

function interopRequireDefaultCon (obj) { return obj && obj.__esModule ? obj : { default: obj }; }

const cookieCon2 = interopRequireDefaultCon(cookieCon);

const objectAssignCon = require('object-assign');

const objectAssignCon2 = interopRequireDefaultCon(objectAssignCon);


const IS_NODE = typeof document === 'undefined' || typeof process !== 'undefined' && process.env && process.env.NODE_ENV === 'test';
let rawCookieCon = {};
let resCon;

function isResWritableCon () {
    return resCon && !resCon.headersSent;
}

function load (name, doNotParse) {
    const cookies = IS_NODE ? rawCookieCon : cookieCon2.default.parse(document.cookie);
    let cookieVal = cookies && cookies[name];

    if (typeof doNotParse === 'undefined') {
        doNotParse = !cookieVal || cookieVal[0] !== '{' && cookieVal[0] !== '[';
    }

    if (!doNotParse) {
        try {
            cookieVal = JSON.parse(cookieVal);
        } catch (err) {
            // Not serialized object
        }
    }

    return cookieVal;
}

function loadAll (doNotParse) {
    const cookies = IS_NODE ? rawCookieCon : cookieCon2.default.parse(document.cookie);
    let cookieVal = cookies;

    if (typeof doNotParse === 'undefined') {
        doNotParse = !cookieVal || cookieVal[0] !== '{' && cookieVal[0] !== '[';
    }

    if (!doNotParse) {
        try {
            cookieVal = JSON.parse(cookieVal);
        } catch (err) {
            // Not serialized object
        }
    }

    return cookieVal;
}

function select (regex) {
    const cookies = IS_NODE ? rawCookieCon : cookieCon2.default.parse(document.cookie);

    if (!cookies) {
        return {};
    }

    if (!regex) {
        return cookies;
    }

    return Object.keys(cookies).reduce(function (accumulator, name) {
        if (!regex.test(name)) {
            return accumulator;
        }

        const newCookie = {};
        newCookie[name] = cookies[name];
        return (0, objectAssignCon2.default)({}, accumulator, newCookie);
    }, {});
}

function save (name, val, opt) {
    rawCookieCon[name] = val;

    // Allow you to work with cookies as objects.
    if ((typeof val === 'undefined' ? 'undefined' : typeofCon(val)) === 'object') {
        rawCookieCon[name] = JSON.stringify(val);
    }

    // Cookies only work in the browser
    if (!IS_NODE) {
        document.cookie = cookieCon2.default.serialize(name, rawCookieCon[name], opt);
    }

    if (isResWritableCon() && resCon.cookie) {
        resCon.cookie(name, val, opt);
    }
}

function remove (name, opt) {
    delete rawCookieCon[name];

    if (typeof opt === 'undefined') {
        opt = {};
    } else if (typeof opt === 'string') {
        // Will be deprecated in future versions
        opt = { path: opt };
    } else {
        // Prevent mutation of opt below
        opt = (0, objectAssignCon2.default)({}, opt);
    }

    if (typeof document !== 'undefined') {
        opt.expires = new Date(1970, 1, 1, 0, 0, 1);
        opt.maxAge = 0;
        document.cookie = cookieCon2.default.serialize(name, '', opt);
    }

    if (isResWritableCon() && resCon.clearCookie) {
        resCon.clearCookie(name, opt);
    }
}

function setRawCookie (rawCookie) {
    if (rawCookie) {
        rawCookieCon = cookieCon2.default.parse(rawCookie);
    } else {
        rawCookieCon = {};
    }
}

function plugToRequest (req, res) {
    if (req.cookie) {
        rawCookieCon = req.cookie;
    } else if (req.cookies) {
        rawCookieCon = req.cookies;
    } else if (req.headers && req.headers.cookie) {
        setRawCookie(req.headers.cookie);
    } else {
        rawCookieCon = {};
    }

    resCon = res;

    return function unplug () {
        resCon = null;
        rawCookieCon = {};
    };
}

exports.default = {
    setRawCookie,
    load,
    loadAll,
    select,
    save,
    remove,
    plugToRequest
};